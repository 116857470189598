import merge from '~lodash.mergewith'
import * as CoreSdk from '~@sentry/core'
import { captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } from '~@sentry/browser'
import { replayIntegration } from '~@sentry/browser'
import { extraErrorDataIntegration, reportingObserverIntegration } from '~@sentry/integrations'
import { init, browserTracingIntegration } from '~@sentry/vue'

export { init }
export const SentrySdk = { ...CoreSdk, ...{ captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } }

/** @type {string[]} */
const DISABLED_INTEGRATION_KEYS = []

/**
 * @typedef {Parameters<typeof init>[0]} InitConfig
 * @param {import('@nuxt/types').Context} ctx
 * @return {Promise<InitConfig>}
 */
export function getConfig (ctx) {
  /** @type {InitConfig} */
  const config = {
    dsn:"https:\u002F\u002Fe60634019ccc5711906ce7e30a1b6a3d@sentry.idacloud.ru\u002F339",
    environment:"develop",
    ignoreErrors:["comagic.ru","yandex.ru","my.smartis.bi","mindbox.ru","stats.g.doubleclick.net","smartcallback.ru","adriver.ru","google-analytics.com"],
    ignoreTransactions:["comagic.ru","yandex.ru","my.smartis.bi","mindbox.ru","stats.g.doubleclick.net","smartcallback.ru","adriver.ru","google-analytics.com"],
    denyUrls:["comagic.ru","yandex.ru","my.smartis.bi","mindbox.ru","stats.g.doubleclick.net","smartcallback.ru","adriver.ru","google-analytics.com"],
    initialScope:{"tags":{"platform":"nuxt","project":"portal","nuxtContext":"client-side"}},
    beforeSend:function(event) {
        // Add client-specific tags
        event.tags = {
          ...event.tags,
          side: 'client',
          vue_version: '2.x'
        };
        return event;
      },
    replaysSessionSampleRate:0.03,
    replaysOnErrorSampleRate:1,
    tracesSampleRate:0.03,
  }

  /** @type {NonNullable<InitConfig>['integrations']} */
  const resolvedIntegrations = [
    extraErrorDataIntegration(),
    reportingObserverIntegration({ types:["crash"] }),
    replayIntegration(),
  ]

  resolvedIntegrations.push(browserTracingIntegration({
    router: ctx.app.router,
    ...{"routeLabel":"name"},
    ...{},
  }))
  merge(config, {"trackComponents":true}, {"tracesSampleRate":0.03})

  config.integrations = (defaultIntegrations) => {
    return [
      ...defaultIntegrations.filter(integration => !DISABLED_INTEGRATION_KEYS.includes(integration.name)),
      ...resolvedIntegrations,
    ]
  }
  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
