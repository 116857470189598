import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f43197a8 = () => interopDefault(import('../portal/pages/employers/index.vue' /* webpackChunkName: "pages/employers/index" */))
const _9962795e = () => interopDefault(import('../portal/pages/job/index.vue' /* webpackChunkName: "pages/job/index" */))
const _6fa5b9c4 = () => interopDefault(import('../portal/pages/job/index/_type/index.vue' /* webpackChunkName: "pages/job/index/_type/index" */))
const _7b32a2a4 = () => interopDefault(import('../portal/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _2cf28f5f = () => interopDefault(import('../portal/pages/marketplace/index.vue' /* webpackChunkName: "pages/marketplace/index" */))
const _1888ef10 = () => interopDefault(import('../portal/pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _301735bd = () => interopDefault(import('../portal/pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _559e8d6e = () => interopDefault(import('../portal/pages/profile/index/company/index.vue' /* webpackChunkName: "pages/profile/index/company/index" */))
const _4d4a5c58 = () => interopDefault(import('../portal/pages/profile/index/contacts/index.vue' /* webpackChunkName: "pages/profile/index/contacts/index" */))
const _588febef = () => interopDefault(import('../portal/pages/profile/index/expert/index.vue' /* webpackChunkName: "pages/profile/index/expert/index" */))
const _25a46c92 = () => interopDefault(import('../portal/pages/profile/index/expert/index/grading/index.vue' /* webpackChunkName: "pages/profile/index/expert/index/grading/index" */))
const _35a54059 = () => interopDefault(import('../portal/pages/profile/index/expert/index/results/index.vue' /* webpackChunkName: "pages/profile/index/expert/index/results/index" */))
const _3a4e248c = () => interopDefault(import('../portal/pages/profile/index/expert/index/voting/index.vue' /* webpackChunkName: "pages/profile/index/expert/index/voting/index" */))
const _85d8601a = () => interopDefault(import('../portal/pages/profile/index/info/index.vue' /* webpackChunkName: "pages/profile/index/info/index" */))
const _154950a4 = () => interopDefault(import('../portal/pages/profile/index/job/index.vue' /* webpackChunkName: "pages/profile/index/job/index" */))
const _18d29183 = () => interopDefault(import('../portal/pages/profile/index/responses/index.vue' /* webpackChunkName: "pages/profile/index/responses/index" */))
const _7df72ad2 = () => interopDefault(import('../portal/pages/profile/index/safety/index.vue' /* webpackChunkName: "pages/profile/index/safety/index" */))
const _0a4bd320 = () => interopDefault(import('../portal/pages/profile/index/verification/index.vue' /* webpackChunkName: "pages/profile/index/verification/index" */))
const _31130a2f = () => interopDefault(import('../portal/pages/profile/index/job/resume/_id/index.vue' /* webpackChunkName: "pages/profile/index/job/resume/_id/index" */))
const _b6be95f6 = () => interopDefault(import('../portal/pages/profile/index/job/vacancy/_id/index.vue' /* webpackChunkName: "pages/profile/index/job/vacancy/_id/index" */))
const _30177c53 = () => interopDefault(import('../portal/pages/profile/index/job' /* webpackChunkName: "" */))
const _6af32c14 = () => interopDefault(import('../portal/pages/profile/index/job/resume/_id' /* webpackChunkName: "" */))
const _497a4a6a = () => interopDefault(import('../portal/pages/profile/index/job/vacancy/_id' /* webpackChunkName: "" */))
const _177c091f = () => interopDefault(import('../portal/pages/rating/index.vue' /* webpackChunkName: "pages/rating/index" */))
const _3fe36b6a = () => interopDefault(import('../portal/pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _17fd8c0c = () => interopDefault(import('../portal/pages/soon.vue' /* webpackChunkName: "pages/soon" */))
const _65446e1c = () => interopDefault(import('../portal/pages/ui-kit.vue' /* webpackChunkName: "pages/ui-kit" */))
const _7cab27cf = () => interopDefault(import('../portal/pages/media/edition-contacts/index.vue' /* webpackChunkName: "pages/media/edition-contacts/index" */))
const _a1868720 = () => interopDefault(import('../portal/pages/rating/expert/index.vue' /* webpackChunkName: "pages/rating/expert/index" */))
const _3c9c90f3 = () => interopDefault(import('../portal/pages/rating/methodology/index.vue' /* webpackChunkName: "pages/rating/methodology/index" */))
const _084a6fa3 = () => interopDefault(import('../portal/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1ed45e67 = () => interopDefault(import('../portal/pages/employers/_slug/index.vue' /* webpackChunkName: "pages/employers/_slug/index" */))
const _728d475a = () => interopDefault(import('../portal/pages/marketplace/_slug/index.vue' /* webpackChunkName: "pages/marketplace/_slug/index" */))
const _1bf9625d = () => interopDefault(import('../portal/pages/marketplace/_slug/index/index.vue' /* webpackChunkName: "pages/marketplace/_slug/index/index" */))
const _cfa8b13a = () => interopDefault(import('../portal/pages/media/_id/index.vue' /* webpackChunkName: "pages/media/_id/index" */))
const _1d2a9106 = () => interopDefault(import('../portal/pages/job/_type/_id/index.vue' /* webpackChunkName: "pages/job/_type/_id/index" */))
const _382d4c5e = () => interopDefault(import('../portal/pages/media/_id/_slug/index.vue' /* webpackChunkName: "pages/media/_id/_slug/index" */))
const _d0565a02 = () => interopDefault(import('../portal/pages/marketplace/_slug' /* webpackChunkName: "" */))
const _1b1bd3c3 = () => interopDefault(import('../portal/pages/job/index/_type' /* webpackChunkName: "" */))
const _7ef3f8aa = () => interopDefault(import('../portal/pages/job/_type/_id' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: '_active-link',
  linkExactActiveClass: '_exact-link',
  scrollBehavior,

  routes: [{
    path: "/employers",
    component: _f43197a8,
    name: "employers"
  }, {
    path: "/job",
    component: _9962795e,
    name: "job",
    children: [{
      path: ":type",
      component: _6fa5b9c4,
      name: "job-index-type"
    }]
  }, {
    path: "/maintenance",
    component: _7b32a2a4,
    name: "maintenance"
  }, {
    path: "/marketplace",
    component: _2cf28f5f,
    name: "marketplace"
  }, {
    path: "/media",
    component: _1888ef10,
    name: "media"
  }, {
    path: "/profile",
    component: _301735bd,
    name: "profile",
    children: [{
      path: "company",
      component: _559e8d6e,
      name: "profile-index-company"
    }, {
      path: "contacts",
      component: _4d4a5c58,
      name: "profile-index-contacts"
    }, {
      path: "expert",
      component: _588febef,
      name: "profile-index-expert",
      children: [{
        path: "grading",
        component: _25a46c92,
        name: "profile-index-expert-index-grading"
      }, {
        path: "results",
        component: _35a54059,
        name: "profile-index-expert-index-results"
      }, {
        path: "voting",
        component: _3a4e248c,
        name: "profile-index-expert-index-voting"
      }]
    }, {
      path: "info",
      component: _85d8601a,
      name: "profile-index-info"
    }, {
      path: "job",
      component: _154950a4,
      name: "profile-index-job"
    }, {
      path: "responses",
      component: _18d29183,
      name: "profile-index-responses"
    }, {
      path: "safety",
      component: _7df72ad2,
      name: "profile-index-safety"
    }, {
      path: "verification",
      component: _0a4bd320,
      name: "profile-index-verification"
    }, {
      path: "job/resume/:id",
      component: _31130a2f,
      name: "profile-index-job-resume-id"
    }, {
      path: "job/vacancy/:id",
      component: _b6be95f6,
      name: "profile-index-job-vacancy-id"
    }, {
      path: "/profile/resumes",
      component: _30177c53,
      name: "profile-resumes"
    }, {
      path: "/profile/resumes/:id",
      component: _6af32c14,
      name: "profile-resumes-id"
    }, {
      path: "/profile/vacancies",
      component: _30177c53,
      name: "profile-vacancies"
    }, {
      path: "/profile/vacancies/:id",
      component: _497a4a6a,
      name: "profile-vacancies-id"
    }]
  }, {
    path: "/rating",
    component: _177c091f,
    name: "rating"
  }, {
    path: "/search",
    component: _3fe36b6a,
    name: "search"
  }, {
    path: "/soon",
    component: _17fd8c0c,
    name: "soon"
  }, {
    path: "/ui-kit",
    component: _65446e1c,
    name: "ui-kit"
  }, {
    path: "/media/edition-contacts",
    component: _7cab27cf,
    name: "media-edition-contacts"
  }, {
    path: "/rating/expert",
    component: _a1868720,
    name: "rating-expert"
  }, {
    path: "/rating/methodology",
    component: _3c9c90f3,
    name: "rating-methodology"
  }, {
    path: "/",
    component: _084a6fa3,
    name: "index"
  }, {
    path: "/employers/:slug",
    component: _1ed45e67,
    name: "employers-slug"
  }, {
    path: "/marketplace/:slug",
    component: _728d475a,
    children: [{
      path: "",
      component: _1bf9625d,
      name: "marketplace-slug-index"
    }]
  }, {
    path: "/media/:id",
    component: _cfa8b13a,
    name: "media-id"
  }, {
    path: "/job/:type/:id",
    component: _1d2a9106,
    name: "job-type-id"
  }, {
    path: "/media/:id/:slug",
    component: _382d4c5e,
    name: "media-id-slug"
  }, {
    path: "/marketplace/:slug",
    component: _d0565a02,
    name: "company-id",
    children: [{
      path: "job",
      component: _1b1bd3c3,
      name: "company-job"
    }, {
      path: "job/:id",
      component: _7ef3f8aa,
      name: "company-job-id"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
