const middleware = {}

middleware['check-jivo'] = require('../portal/middleware/check-jivo.js')
middleware['check-jivo'] = middleware['check-jivo'].default || middleware['check-jivo']

middleware['check-role'] = require('../portal/middleware/check-role.js')
middleware['check-role'] = middleware['check-role'].default || middleware['check-role']

middleware['redirects'] = require('../portal/middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

export default middleware
